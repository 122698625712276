@tailwind base;
@tailwind components;
@tailwind utilities;

/* :root {
    --animation-btn: 0;
    --animation-input: 0;
  } */

  .table th:first-child {
    position: inherit;
    position: inherit;
    /* left: 0px; */
    /* z-index: 11; */
}